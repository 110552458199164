import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import React from "react"
import BackToHome from "../components/BackToHome"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout hideHamburger={true}>
    <SEO />
    <h2><FormattedHTMLMessage id={"404-title"} /></h2>
    <p><FormattedHTMLMessage id={"404-text"} /></p>
    <BackToHome />
  </Layout>
)

export default NotFoundPage
