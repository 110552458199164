import { FormattedHTMLMessage } from "gatsby-plugin-intl"
import React from "react"

const BackToHome = () => (
  <p style={{
    textAlign: 'center'
  }}>
    <a href="/">
      <u><FormattedHTMLMessage id={"BackToHome"} /></u>
    </a>
  </p>
)

export default BackToHome
